import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import slugify from 'slugify'

import { ProductCard } from 'src/components/product/card'
import { ProductInt } from 'src/interfaces/product'
import { CardCTA } from 'src/components/product/cardCta'

import {
  LinkArrow
} from 'src/components/svgs'

export interface ProductGridProps {
  data: {
    title?: string
    chefFirst?: boolean
    shortDescription?: string
    showAll?: {
      slug: string
    }
    products?: [ProductInt]
  }
}

export const ProductGrid = ({ data }: ProductGridProps) => {
  const {
    title,
    products,
    showAll,
    chefFirst = false
  } = data

  let length = 0
  products?.forEach(product => {
    if (product.product) {
      if (chefFirst !== true && product.product.productType !== 'deck') {
        length++
      }
    } else {
      length++
    }
  })


  return (
    <React.Fragment>
      {title && (<div className={cx('bn x collection__filter-ghost', `js-${slugify(title, { lower: true })}`)} />)}
      {title && (<div className={cx('js-filter-hide psy z2 collection__grid-header hide__filtering col c14')}>
          <div className='p1 pl3--800 pr3--800 top bg--white df jcb aic'>
            <h3 className='m0 p0'>{title}</h3>
            {showAll && (
              <Link className='link-underline caps s16 medium founders' to={`/collections/${showAll.slug}`}>Shop all  <LinkArrow /></Link>
            )}
          </div>
        </div>
      )}
      {products && products.map((singleItem, i) => {
        if (singleItem.product) {
          const {
            productType,
            dietary,
            composition,
            form,
            _id,
            serving
          } = singleItem.product
          return (
            <div className={cx('col c7 product__card-col',
              dietary && dietary.join(' '),
              serving,
              composition,
              form,
              chefFirst === true ? 'chef' : productType, {
              'c4a--800': chefFirst === true || productType !== 'deck',
              'c14 bg--off-white': productType === 'deck'
            })} key={`${_id}${i}`}>
              <ProductCard key={_id} chefFirst={chefFirst} {...singleItem.product} />
            </div>
          )
        } else {
          return <CardCTA key={i} data={singleItem} />
        }
      })}
      {products && ((products[products.length - 1].product && products[products.length - 1].product.productType !== 'deck') || !products[products.length - 1].product) && (
        <>
          {length % 3 && (
            <>
              {length % 3 !== 2 ? (
                <>
                  <div className='product__card-col hide__filtering bn col c4a--800' />
                  <div className='product__card-col hide__filtering bn col c4a--800' />
                </>
              ) : (
                <>
                  <div className='product__card-col hide__filtering bn col c4a--800' />
                </>
              )}
            </>
          )}
        </>
      )}
      {showAll && (
        <div className='col pr z1 product__card-col bg--off-white c14'>
          <div className='p2 pr z1 p3--800'>
            <Link className='button db x' to={`/collections/${showAll.slug}`}>
              <span className='x s18'>Shop All {title}</span>
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
import React from 'react'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'

export interface CardCTAProps {
  data: {
    cta: any[]
  }
}

export const CardCTA = ({ data }: CardCTAProps) => {
  const {
    cta
  } = data
  return (
    <div className='col c4a--800 bg--white x product__card-cta product__card-col hide__filtering'>
      <div className='p2 p3--800'>
        <BlockContent blocks={cta} serializers={Serializer} />
      </div>
    </div>
  )
}
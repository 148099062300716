import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"
import { Link } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'
import { ProductInt } from 'src/interfaces/product'
import { ProductForm } from './form'

import { client, useAddItemToCart } from 'src/context/siteContext'

import {
  Avatar
} from 'src/components/svgs'

const dietaryBlock = {
  'gluten-free': 'GF',
  'dairy-free': 'DF',
  'vegetarian': 'V',
  'vegan': 'VG'
}

const servingBlock = {
  'threeFour': '3-4',
  'oneTwo': '1-2',
  'fivePlus': '5+'
}

const compositionBlock = {
  'pure-spice': 'Pure Spice',
  'spice-blend': 'Spice Blend'
}

export const ProductCard = ({
  _id,
  title,
  slug,
  imageId,
  clpHover,
  chefFirst,
  productType,
  linkedProducts,
  customTitle,
  variants,
  description,
  serving,
  badgeText,
  composition,
  dietary,
  form,
  chef,
  content: {
    main,
    shopify
  }
}: ProductInt ) => {
  const [isHover, setIsHover]= useState(false)
  const [productFetch, setProductFetch] = useState(false)
  useEffect(() => {
    if (isHover && !productFetch) {
      setProductFetch(true)
    }
  }, [isHover])

  const renderType = (type) => {
    switch (type) {
      case 'recipe':
        return null
      case 'set':
        return (
          <div className='product__card-avatar ba s12 medium  mr1 mb1 caps df jcc aic'
            ><span className='df medium aic'>{badgeText ? `${badgeText}` : 'Spice Cards'}</span>
          </div>
        )
      case 'deck':
        return (
          <div className='product__card-avatar ba s12 medium  mr1 mb1 caps df jcc aic'
            ><span className='df medium aic'>{ linkedProducts && linkedProducts.length} {badgeText ? `${badgeText}` : 'Spice Cards'}</span>
          </div>
        )
    }
    return null
  }

  return (
    <div onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={cx('p1 y p3--800 pr z1 product__card', chefFirst !== true && productType, {
      'pt3--800': chefFirst !== true && productType === 'deck'
    })} key={_id}>
      <Link className='block pr z1 founders' to={`/products/${slug}`}>
        {main.mainImage ? (
          <div className='product__card-image pr of--hidden'>
            <AnimatePresence>
              {chefFirst && isHover ? (
                  <motion.div key={imageId} className='pa y z1 x top left' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
                    <Image className='block pa y object-fit x' width={productType === 'deck' ? 1800 : 1800} imageId={imageId} alt={title} />
                  </motion.div>
              ) : (
                <>
                  {isHover && clpHover && (
                    <motion.div key={clpHover.imageId} className='pa y z1 x top left' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} >
                      <Image className='block pa y object-fit x' width={productType === 'deck' ? 1800 : 1800} imageId={clpHover.imageId} alt={title} />
                    </motion.div>
                  )}
                </>
              )}
            </AnimatePresence>
            {chefFirst && chef.image ? (
              <Image className='block pa y object-fit x' width={productType === 'deck' ? 1800 : 1800} imageId={chef.image.imageId} alt={chef.image.altText} />
            ) : (
              <Image className='block pa y object-fit x' width={productType === 'deck' ? 1800 : 1800} imageId={imageId} alt={title} />
            )}
          </div>
        ): (
          <div className='product__card-image' />
        )}
      </Link>
        <div className='row'>
          <div className={cx('col', {
            'c14': productType !== 'deck',
            'c14 c9--800 deck__col': productType === 'deck'  && chefFirst !== true
          })}> 

          <Link className='block pr z1 founders' to={`/products/${slug}`}>
            {chefFirst ? (
              <>
                <h3 className='wb--break-word founders pt1 pt2--800 pb1 s16 s24--800'>Chef {chef.name}</h3>
                <span className='caps s10 s12--600 medium db'>{title}</span>
              </>
            ) : (
              <>
                <h3 className='wb--break-word founders pt1 pt2--800 pb1 s16 s24--800'>{title}</h3>
                {chef && (
                  <span className='caps s10 s12--600 medium db'>By Chef {chef.name}</span>
                )}
              </>
            )}
            
            <div className='df jcs fw ais mt1'>
              {renderType(productType)}
              {serving && (
                <div className='product__card-avatar ba s12 medium  mr1 mb1 caps df jcc aic'><span className='df medium aic'><Avatar />{servingBlock[serving]}</span></div>
              )}
              {form && (
                <div className='product__card-avatar ba s12 medium  mr1 mb1 caps df jcc aic'><span className='medium'>{form === 'chopped' ? 'Cut & Sifted' : form}</span></div>
              )}
              {composition && (
                <div className='product__card-avatar ba s12 medium  mr1 mb1 caps df jcc aic'><span className='medium'>{compositionBlock[composition]}</span></div>
              )}
              {dietary && (
                <>
                  {dietary.map((diet, i) => (
                    <div className='product__card-dietary ba s12 bold mr1 caps df jcc aic' key={i}>
                      <span className='medium'>{dietaryBlock[diet]}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
            </Link>
            <div className='x mt05 pt1 show--800 pt2--800'>
              {productFetch ?
               (<ProductForm
                  {...shopify}
                  productType={productType}
                  variants={variants}
                  linked={linkedProducts}
                  showQuantity={false}
                  waitlist={false}
                  buttonClass={`button__product-card caps founders`}
                  addText={`+ Add ${productType} to my Pantry`} />
               ) : (
                 <div className='p1 pb2' />
               )}
            </div>
          </div>
          {productType === 'deck' && chefFirst !== true && (
            <div className='col c14 deck__desc c5--800'>
              <div className='p0 p2--800'>
                {description && (<BlockContent blocks={description} serializers={Serializer} />)}
              </div>
            </div>
          )}
        </div>
    </div>
  )
}

import React from 'react'
import loadable from '@loadable/component'

import { StandardText, StandardTextProps } from 'src/components/global/standardText'
import { ProductGrid, ProductGridProps } from 'src/components/global/productGrid'
import { TwoColumnModule, TwoColumnModuleProps } from 'src/components/global/twoColumnModule'
import { ImageModule, ImageModuleProps } from 'src/components/global/imageModule'
import { TextHero, TextHeroProps } from 'src/components/global/textHero'



const ImageAndText = loadable(() => import('src/components/global/imageAndText'))
const ProductRow = loadable(() => import('src/components/global/productRow'))
const ProductScroll = loadable(() => import('src/components/global/productScroll'))
const DoubleCTA = loadable(() => import('src/components/global/doubleCta'))
const ReduceWaste = loadable(() => import('src/components/global/reduceWaste'))
const Deck = loadable(() => import('src/components/global/deck'))
const PressModule = loadable(() => import('src/components/global/pressModule'))
const ProductCarousel = loadable(() => import('src/components/global/productCarousel'))
const ShoppableHero = loadable(() => import('src/components/global/shoppableHero'))

const NestedProductHero = loadable(() => import('src/components/global/nestedProductHero'))
const TextBlock = loadable(() => import('src/components/global/textBlock'))
const CTABlock = loadable(() => import('src/components/global/ctaBlock'))
const Marquee = loadable(() => import('src/components/global/marquee'))

const BadgesHero = loadable(() => import('src/components/global/badgesHero'))
const TwoPodColumns = loadable(() => import('src/components/global/twoPodColumns'))
const ShopAllCards = loadable(() => import('src/components/global/shopAllCardsMarquee'))
const MarketingImageText = loadable(() => import('src/components/global/marketingImageText'))

// About
const Enlightenment = loadable(() => import('src/components/about/enlightenment'))
const TextImageArray = loadable(() => import('src/components/about/textImageArray'))
const AboutAnything = loadable(() => import('src/components/about/aboutAnything'))
const AboutFreshness = loadable(() => import('src/components/about/aboutFreshness'))
const AboutImageAndText = loadable(() => import('src/components/about/aboutImageAndText'))

// Single

const Faqs = loadable(() => import('src/components/global/faqs'))

export const Modules = ({ reactModule, type }: { type: string, reactModule: any }) => {
  switch(type) {
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps["data"]} />
    case 'productGrid':
      return <ProductGrid data={reactModule as ProductGridProps["data"]} />
    case 'twoColumnModule':
      return <TwoColumnModule data={reactModule as TwoColumnModuleProps["data"]} />
    case 'ctaBlock':
      return <CTABlock data={reactModule as TextHeroProps["data"]} />
    case 'textHero':
      return <TextHero data={reactModule as TextHeroProps["data"]} />
    case 'imageModule':
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />
    case 'imageAndText':
      return <ImageAndText data={reactModule} />
    case 'reduceWaste':
      return <ReduceWaste data={reactModule} />
    case 'doubleCta':
      return <DoubleCTA data={reactModule} />
    case 'pressModule':
      return <PressModule data={reactModule} />
    case 'nestedProductHero':
      return <NestedProductHero data={reactModule} />
    case 'marquee':
      return <Marquee data={reactModule} />
    case 'shopAllCardsMarquee':
      return <ShopAllCards data={reactModule} />
    case 'marketingImageText':
      return <MarketingImageText data={reactModule} />
    case 'aboutFreshness':
      return <AboutFreshness data={reactModule} />
    case 'aboutTryAnything':
      return <AboutAnything data={reactModule} />
    case 'badgesHero':
      return <BadgesHero data={reactModule} />
    case 'twoPodColumns':
      return <TwoPodColumns data={reactModule} />
    case 'textBlock':
      return <TextBlock data={reactModule} />
    case 'faqs':
      return <Faqs data={reactModule} />
    case 'productScroll':
      return <ProductScroll data={reactModule} />
    case 'shoppableHero':
      return <ShoppableHero data={reactModule} />
    case 'aboutImageAndText':
      return <AboutImageAndText data={reactModule} />
    case 'aboutEnlightenment':
      return <Enlightenment data={reactModule} />
    case 'aboutTextImageArray':
      return <TextImageArray data={reactModule} />
    case 'productCarousel':
      return <ProductCarousel data={reactModule} />
    case 'productRow':
      return <ProductRow data={reactModule} />
    case 'reference':
      if (reactModule.deck) {
        return <Deck data={reactModule.deck} />
      }
      return null
    default:
      return (<h1 style={{ display: '' }}>{type}</h1>)
  }
}